<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start">
            <label>{{ $t( "Satır") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">{{ $t( label) }}</span>
              </template>
              <template #option="{ label }">
                <span>{{ $t(label) }}</span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <b-table
        :striped="false"
        :bordered="true"
        :hover="true"
        :busy="show"
        :items="listData"
        :fields="translatedTableColumns"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
        :sticky-header="stickyHeader"
        class="cariTable"
      >
        <template #cell(PROFORMA)="data">
          <b-row>
            <b-col cols="6">
              <b-button
                size="sm"
                pill
                variant="outline-warning"
                @click="detay(data.item)"
                :id="`printButton${data.item.EVRAKNO}`"
              >
                <feather-icon icon="PrinterIcon" size="16" />
              </b-button>
              <b-tooltip :title="$t('Goruntule')" :target="`printButton${data.item.EVRAKNO}`" />
            </b-col>
            <b-col cols="6">
              <b-button
                size="sm"
                pill
                variant="outline-success"
                @click="siparisTekrarla(data.item)"
                :id="`sipButton${data.item.EVRAKNO}`"
              >
                <feather-icon icon="RefreshCcwIcon" size="16" />
              </b-button>
              <b-tooltip
                :title="$t('Siparişi Tekrarla')"
                :target="`sipButton${data.item.EVRAKNO}`"
              />
            </b-col>
          </b-row>
        </template>
        <template #cell(STATU)="data">
          <template v-if="data.item.STATU =='bekliyor'">
            <b-badge pill variant="light-warning">
              <feather-icon icon="ClockIcon" size="16" />
              {{$t('Beklemede')}}
            </b-badge>
          </template>
          <template v-if="data.item.STATU =='onaylandı'">
            <b-badge pill variant="light-success">
              <feather-icon icon="CheckCircleIcon" size="16" />
              {{$t('Onaylandı')}}
            </b-badge>
          </template>
          <template v-if="data.item.STATU =='iptal'">
            <b-badge pill variant="light-danger">
              <feather-icon icon="XCircleIcon" size="16" />
              {{$t('iptal Edildi')}}
            </b-badge>
          </template>
        </template>
        <template #cell(URUNSAYISI)="data">
          <div style="text-align:right">
            <p>{{data.item.URUNSAYISI}}</p>
          </div>
        </template>
        <template #cell(TOTALNET)="data">
          <div style="text-align:right">
            <p>{{ data.item.TOTALNET }}</p>
          </div>
        </template>
        <template #cell(TOTALCARTONS)="data">
          <div style="text-align:right">
            <p>{{ data.item.TOTALCARTONS }}</p>
          </div>
        </template>
        <template #cell(TOTALGRAND)="data">
          <div style="text-align:right">
            <p style="color:black">{{ data.item.TOTALGRAND+" "+ data.item.PRICEUNIT2 }}</p>
          </div>
        </template>
        <template #head(CREATEDATE)>
          <div style="text-align:center">
            <p>{{$t('Sipariş Tarihi') }}</p>
          </div>
        </template>
        <template #head(DELIVERYDATE)>
          <div style="text-align:center">
            <p>{{$t('Teslim Tarihi') }}</p>
          </div>
        </template>
        <template #cell(CREATEDATE)="data">
          <div style="text-align:center">
            <p>{{ data.item.CREATEDATE }}</p>
          </div>
        </template>
        <template #cell(DELIVERYDATE)="data">
          <div style="text-align:center">
            <p>{{ data.item.DELIVERYDATE }}</p>
          </div>
        </template>
      </b-table>
      <div class="m-2">
        <b-row>
          <b-col cols="10"></b-col>
          <b-col cols="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BTooltip
} from "bootstrap-vue";
import { t } from "../@core/libs/i18n/utils";
import moment from "moment";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { th } from "vuejs-datepicker/src/locale";
function formatSayi(sayi, ozellestirilmisSecenekler) {
  return new Intl.NumberFormat(undefined, ozellestirilmisSecenekler).format(
    sayi
  );
}

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    BProgress,
    BProgressBar,
    moment,
    vSelect,
    BTooltip
  },
  computed: {
    // ceviri kısmının calisma sekli
    translatedTableColumns() {
      return this.tableColumns.map(column => {
        return { ...column, label: this.$t(column.label) };
      });
    },
    rows() {
      return this.listData.length;
    }
  },

  data() {
    return {
      ozellestirilmisSecenekler: {
        minimumFractionDigits: 2, // küsürat kısmının minimum hane sayısı
        maximumFractionDigits: 2, // küsürat kısmının maksimum hane sayısı
        minimumIntegerDigits: 2, // tam sayı kısmının minimum hane sayısı
        useGrouping: true // binlik grup ayırıcıları kullanılsın mı?
      },
      show: false,
      stickyHeader: true,
      userData: "",
      listDataAll: [],
      listData: [],
      perPage: 8,
      perPageOptions: [8, 16, 50, 100],
      currentPage: 1,
      totalInvoices: 0,
      statusFilter: "Tümü",
      statusOptions: ["Beklemede", "Onaylandı", "İptal", "Tümü"],
      searchQuery: "",
      htmlTemp: "",
      tableColumns: [
        { key: "STATU", class: "STATU", label: "Durum", sortable: true },
        { key: "EVRAKNO", class: "EVRAKNO", label: "Evrak No", sortable: true },
        {
          key: "CREATEDATE",
          class: "createDate",
          label: "Sipariş Tarihi",
          sortable: true
        },
        {
          key: "DELIVERYDATE",
          class: "DELIVERYDATE",
          label: "Teslim Tarihi",
          sortable: true
        },
        {
          key: "TOTALCARTONS",
          class: "TOTALCARTONS",
          label: "Toplam Koli",
          sortable: true
        },
        {
          key: "URUNSAYISI",
          class: "URUNSAYISI",
          label: "Ürün Sayısı",
          sortable: true
        },
        {
          key: "TOTALNET",
          class: "TOTALNET",
          label: "Toplam Net (M3)",
          sortable: true
        },

        {
          key: "TOTALGRAND",
          class: "TOTALGRAND",
          label: "Toplam Fiyat",
          sortable: true
        },
        {
          key: "PROFORMA",
          class: "proformaDetay",
          label: "Detay"
        }
      ]
    };
  },

  created() {
    var uData = JSON.parse(localStorage.getItem("B2BUserData"));
    this.userData = uData;
    this.listele();
    this.$route.query.orders = [];
  },
  watch: {
    statusFilter(val) {
      // console.log(val);
      this.refleksData(val);
    }
  },
  methods: {
    async siparisTekrarla(item) {
      var evrakSatirlar = await this.satirlariGetir(item.EVRAKNO);
      this.$router.push({
        path: "/urunler",
        query: { orders: evrakSatirlar }
      });
    },
    async listele() {
      this.show = true;
      this.kriterler1 = {
        metin1: this.userData.musteriKod,
        queryKey: "B2b_d7k_Siparisleri"
      };
      await this.$http
        .post("v1/Dynamic/GetQueryReplaceData", this.kriterler1)
        .then(response => {
          this.show = false;
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);
            for (var i = 0; i < satirlar.length; i++) {
              satirlar[i].TOTALNET = formatSayi(
                satirlar[i].TOTALNET,
                this.ozellestirilmisSecenekler
              );

              satirlar[i].TOTALGRAND = formatSayi(
                satirlar[i].TOTALGRAND,
                this.ozellestirilmisSecenekler
              );
            }
            this.listDataAll = satirlar;
            this.listData = this.listDataAll;
            //console.log(satirlar);
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error, "Hata");
        });
    },
    async detay(item) {
      this.show = true;
      var evrakSatirlar = await this.satirlariGetir(item.EVRAKNO);
      var evrakBilgiler = await this.evrakGetir(item.EVRAKNO);
      this.htmlTemp = await this.etiketGetir();
      this.etiketDuzenle(item, evrakBilgiler, evrakSatirlar);
      this.show = false;
    },
    async satirlariGetir(evrakNo) {
      this.kriterler1 = {
        metin1: evrakNo,
        queryKey: "B2b_Proforma_GetSatir"
      };
      var response = await this.$http.post(
        "v1/Dynamic/GetQueryReplaceData",
        this.kriterler1
      );

      if (response.status == 200) {
        var satirlar = JSON.parse(response.data.data.dataJson);
        return satirlar;
      } else {
        return "Hata";
      }
    },
    async evrakGetir(evrakNo) {
      this.kriterler1 = {
        metin1: evrakNo,
        queryKey: "B2b_Proforma_GetEvrak"
      };
      var response = await this.$http.post(
        "v1/Dynamic/GetQueryReplaceData",
        this.kriterler1
      );

      if (response.status == 200) {
        var satirlar = JSON.parse(response.data.data.dataJson);
        return satirlar[0];
      } else {
        return "Hata";
      }
    },
    async etiketGetir() {
      var response = await this.$http.get(
        "v1/User/GetLabel?key=" + "B2BProformaHtml"
      );
      if (response.status == 200) {
        var etiketMetni = response.data.data;

        return etiketMetni;
      } else {
        return "HATA";
      }
    },
    etiketDuzenle(satir, evrak, satirlar) {
      var proformaKapat = this.htmlTemp.rowLabelData;

      var satirlarArray = [];
      var say = 0;
      var satirTexts = "";
      var yenisatir = "";
      var openProforma = "";
      var priceUnit = "";
      for (let i = 0; i < satirlar.length; i++) {
        say = say + 1;
        yenisatir =
          "<tr><td>@REF01</td><td>@REF02</td><td style='text-align:right !important'>@REF07</td><td style='text-align:right !important'>@REF03</td><td style='text-align:right !important'>@REF04</td><td style='text-align:right !important'>@REF05</td><td style='text-align:right !important'>@REF06</td></tr>";
        yenisatir = yenisatir.replaceAll(
          "@REF01",
          satirlar[i].KOD + " " + satirlar[i].AD
        );
        yenisatir = yenisatir.replaceAll("@REF02", satirlar[i].INGACIKLAMA);
        var paletToplam = 0;
        paletToplam =
          Number(satirlar[i].PALLET20DC) +
          Number(satirlar[i].PALLET40HQ) +
          Number(satirlar[i].PALLET45HQ) +
          Number(satirlar[i].PALLETREEFER40HQ);
        //Karton Toplam
        yenisatir = yenisatir.replaceAll("@REF03", satirlar[i].TOPLAMKOLI);
        // Karton x Adet Toplam
        var toplamAdet =
          Number(satirlar[i].ADET_MIKTAR) * Number(satirlar[i].TOPLAMKOLI);
        yenisatir = yenisatir.replaceAll("@REF04", formatSayi(toplamAdet));
        // Koli başı fiyat
        var formatliFiyat = formatSayi(
          satirlar[i].PRICE,
          this.ozellestirilmisSecenekler
        );
        yenisatir = yenisatir.replaceAll(
          "@REF05",
          formatliFiyat + " " + satirlar[i].PRICEUNIT2
        );
        // Koli başı fiyat * koli adet
        var satirToplami =
          Number(satirlar[i].PRICE) * Number(satirlar[i].TOPLAMKOLI);
        var formatliToplam = formatSayi(
          satirToplami,
          this.ozellestirilmisSecenekler
        );
        yenisatir = yenisatir.replaceAll(
          "@REF06",
          formatliToplam + " " + satirlar[i].PRICEUNIT2
        );
        yenisatir = yenisatir.replaceAll("@REF07", satirlar[i].RAFOMRU);
        priceUnit = satirlar[i].PRICEUNIT2;

        satirTexts = satirTexts + yenisatir;

        if (say == 14 || i == satirlar.length - 1) {
          satirlarArray.push(satirTexts);
          satirTexts = "";
          say = 0;
        }
      }

      for (let a = 0; a < satirlarArray.length; a++) {
        var proforma = this.htmlTemp.labelData;
        proforma = proforma.replaceAll("@YeniSatir", satirlarArray[a]);

        if (a == satirlarArray.length - 1) {
          proforma = proforma.replaceAll("@AltKisim", proformaKapat);
        } else {
          proforma = proforma.replaceAll(
            "@AltKisim",
            "<h5 style='text-align:center !important'>Next Page</h5>"
          );
        }
        openProforma = openProforma + proforma;
      }

      openProforma = openProforma.replaceAll("@DATE", satir.CREATEDATE);
      openProforma = openProforma.replaceAll("@INVOICE", satir.EVRAKNO);
      openProforma = openProforma.replaceAll("@CONTAINER", "");
      openProforma = openProforma.replaceAll("@DELIVERY", satir.DELIVERYDATE);
      openProforma = openProforma.replaceAll("@SHIPMENT", "");
      openProforma = openProforma.replaceAll("@PAYMENT", "");

      openProforma = openProforma.replaceAll(
        "@PCS",
        formatSayi(evrak.TOTALPCS)
      );
      openProforma = openProforma.replaceAll(
        "@VOLUME",
        formatSayi(evrak.TOTALVOLUME, this.ozellestirilmisSecenekler)
      );
      openProforma = openProforma.replaceAll(
        "@GROSS",
        formatSayi(evrak.TOTALGROSS, this.ozellestirilmisSecenekler)
      );
      openProforma = openProforma.replaceAll(
        "@NET",
        formatSayi(evrak.TOTALNET, this.ozellestirilmisSecenekler)
      );
      openProforma = openProforma.replaceAll(
        "@CARTONS",
        formatSayi(evrak.TOTALCARTON)
      );
      openProforma = openProforma.replaceAll(
        "@TOTALGRAND",
        formatSayi(evrak.TOTALGRAND, this.ozellestirilmisSecenekler) +
          " " +
          satir.PRICEUNIT2
      );
      openProforma = openProforma.replaceAll(
        "@TOTALSUB",
        formatSayi(evrak.TOTALGRAND, this.ozellestirilmisSecenekler) +
          " " +
          satir.PRICEUNIT2
      );
      openProforma = openProforma.replaceAll("@TOTALGROSS", "");

      openProforma = openProforma.replaceAll(
        "@ADRES",
        evrak.ADRES1 + " " + evrak.ADRES2
      );
      openProforma = openProforma.replaceAll("@ALICI", evrak.MUSTERIAD);
      openProforma = openProforma.replaceAll("@EMAIL", evrak.EMAIL);
      openProforma = openProforma.replaceAll("@TELEFON", evrak.TELEFON);
      openProforma = openProforma.replaceAll("@FAX", evrak.FAX);

      //console.log(proforma, proformaKapat, satir, evrak, satirlar);
      this.etiketAc(openProforma);
    },
    etiketAc(proforma) {
      var disp_setting =
        "toolbar=no,location=no,directories=no,menubar=no, scrollbars=no,width=1000, height=2000";
      var myWindow = window.open("", "", disp_setting);
      myWindow.document.open();
      myWindow.document.write(proforma);
      myWindow.document.close();
      myWindow.focus();
    },
    refleksData(tip) {
      if (tip == "Beklemede") {
        var satirlar = this.listDataAll.filter(s => s.STATU == "bekliyor");
        this.listData = satirlar;
      } else if (tip == "Onaylandı") {
        var satirlar = this.listDataAll.filter(s => s.STATU == "onaylandı");
        this.listData = satirlar;
      } else if (tip == "İptal") {
        var satirlar = this.listDataAll.filter(s => s.STATU == "iptal");
        this.listData = satirlar;
      } else if (tip == "Tümü") {
        var satirlar = this.listDataAll;
        this.listData = satirlar;
      } else if (tip == null) {
        var satirlar = this.listDataAll;
        this.listData = satirlar;
      }
    }
  }
};
</script>
 
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style >
.cariTable {
  max-height: 550px !important;
  border-radius: 5px !important;
}
.b-table .proformaDetay {
  width: 140px !important;
}
</style>
 